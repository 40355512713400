.grid-summary {
    display: flex;
    flex-direction: column;
    gap: 8px; 
    padding: 0;
    margin: 16px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    li {
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
            height: auto;
            width: 18px;
            min-width: 18px;
            fill: var(--success);
        }
    }
}
