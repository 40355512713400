@import '../../shared/settings';

.product-links {
    a {
        text-decoration: underline;
        color: $dark-grey;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: $brand;
        }
    }
}
