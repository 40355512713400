@import '../../shared/settings';

.location-preview {
    position: relative;
    background-image: center;
    background-size: cover;
    overflow: hidden;
    text-align: center;

    img {
        object-fit: cover;
    }

    &.location-editable,
    &.location-unknown {
        border: 1px solid $light-grey;
        border-radius: 8px;
        box-sizing: border-box;
    }

    &.location-editable {
        cursor: pointer;
    }

    &.location-unknown {
        background: rgba($lighter, 0.5);
        line-height: 142px;
        color: $grey;
        font-weight: var(--medium);
    }

    > button {
        @extend %nowrap;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;
        padding: 5px 16px;
        height: auto;
        line-height: 24px;
        font-size: 14px;
        font-weight: var(--medium);
    }

    .bottom {
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 -1px 3px 0 rgba(black, 0.1);
    }

    .top {
        top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 -1px -3px 0 rgba(black, 0.1);
    }

    .toggle {
        background: white;
        background-color: white;
        color: $brand;

        svg {
            position: relative;
            top: 2px;
            left: 0;
            transform: none;
            margin-right: 6px;
            width: 14px;
            height: 14px;
            fill: $brand;
        }
    }
}

.row {
    .location-preview {
        transition: height $fast;
        border: 1px solid $light-grey;
        border-radius: 8px;
        width: 100%;
        height: 156px;
    }

    .location-editing {
        border: 1px solid $light-grey;
        border-radius: 8px;
        overflow: hidden;
    }

    .location-editing,
    .location-editing .google-maps {
        height: 312px;
    }
}

.location-preview-pin {
    width: auto;
    height: 50%;
    max-height: 50px;
}

.row-cell {
    .location-preview-link {
        display: block;
        width: 100%;
    }

    .location-preview {
        border: 1px solid $light-grey;
        border-radius: 6px;
        min-width: 46px;

        &::before {
            display: block;
            padding-top: 100%;
            height: 0;
            content: ' ';
        }
    }
}
