@import '../../shared/settings';

.grid-block {
    .product-data {
        h5 {
            margin: 16px 0;
            font-size: 18px;
        }
    }
}

.product-data-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 8px;

    > div {
        padding: 16px;
    }

    svg {
        fill: var(--dark-grey);
        margin-left: 8px;
    }

    p {
        margin: 0;
    }

    & > :first-child {
        border-top-left-radius: 8px;
    }
    & > :first-child + div {
        border-top-right-radius: 8px;
    }

    & > :first-child {
        border-top-left-radius: 8px;
    }
    & > :first-child + div {
        border-top-right-radius: 8px;
    }

    & > :nth-last-child(2) {
        border-bottom-left-radius: 8px;
    }
    & > :last-child {
        border-bottom-right-radius: 8px;
    }

    & > *:nth-child(4n + 1),
    & > *:nth-child(4n + 2) {
        background: var(--background);
    }
}
