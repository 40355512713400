.grid-form {
    > .form-row:first-child {
        label {
            margin-top: 0;
        }
    }

    h4 {
        &:first-child {
            margin-top: 0;
            display: flex;

        button {
            margin-left: auto;
            font-size: 14px;
            text-decoration: underline;
            font-weight: normal;
        }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .grid-block-detail {
        margin-bottom: 16px;
    }
}

.grid-form-future {
    h4 {
        opacity: .5;
    }
}

.grid-check {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        margin: 0;
    }

    .form-row + &,
    .combined-form-rows + & {
        margin-top: 16px;
    }

    & + .grid-check {
        margin-top: 8px;
    }
}
