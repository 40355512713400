@import '../../shared/settings';

.location-header-images {
    height: 472px;
    overflow: hidden;
    position: relative;

    @include respond-to(not-wide) {
        height: 37.75vw;
    }

    &-grid {
        display: grid;
        gap: var(--gutter-size);

        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        height: 100%;
    }

    figure {
        background: $light;
        border-radius: 8px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &-2 {
        grid-template-columns: 1fr calc(40% - 16px);
        grid-template-rows: 1fr;
    }

    &-5 {
        grid-template-columns: calc(60% - 8px) calc(20% - 12px) calc(20% - 12px);
        grid-template-rows: calc(50% - 8px) calc(50% - 8px);

        figure {
            &:first-child {
                grid-row: 1 / 3;
            }
        }
    }

    .images {
        height: 100%;
    }

    @include respond-to(mobile) {
        height: unset;
        aspect-ratio: 714/472;

        .keen-slider__slide {
            border-radius: 0;

            img {
                object-fit: cover;
            }
        }
        .carousel-indicator {
            left: 16px;
            bottom: 16px;
        }
    }

    .more {
        right: 16px;
        bottom: 16px;
        position: absolute;
        background: white;
        color: var(--dark-grey);
        border-radius: 4px;
        line-height: 14px;
        padding: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;

        &,
        * {
            transition: all var(--transition-fast);
        }

        svg {
            margin-right: 8px;
            fill: var(--dark-grey);
        }

        &:hover {
            background-color: var(--brand);
            color: white;

            svg {
                fill: white;
            }
        }
    }
}

.location-header {
    display: flex;

    &,
    .location-info,
    .location-logo {
        transition: all var(--transition-fast);
    }

    .location-logo {
        position: relative;
        height: 100px;
        margin: 0 16px;
        display: flex;
        align-items: flex-end;
    }

    figure {
        background-color: white;
        padding: 8px;
        border-radius: 8px;
        width: 200px;
        height: 200px;

        &.logo-round {
            border-radius: 50%;
            img {
                border-radius: 50%;
            }
        }
    }

    h3 {
        margin: 16px 0 0;
        font-size: 24px;
        line-height: 32px;
    }

    address {
        line-height: 24px;
        font-style: normal;
    }

    .location-properties {
        margin-top: 3px;
        display: flex;
        .location-features {
            margin-left: 3px;
        }
    }

    .location-info {
        flex-grow: 1;
        display: flex;
    }

    .location-title-and-adress {
        flex-grow: 1;
    }

    @include respond-to(mobile) {
        position: relative;
        flex-direction: row-reverse;

        h1 {
            font-size: 20px;
        }

        address {
            font-size: 14px;
        }

        figure {
            width: 100px;
            height: 100px;
        }

        .location-title-and-adress {
            margin-right: 100px;
        }

        .location-logo {
            height: 100px;
            top: -50px;
            right: 0;
            position: absolute;
        }

        .location-info {
            padding-left: var(--gutter-size);
            padding-right: var(--gutter-size);

            flex-grow: 1;
            flex-direction: column;
        }
    }

    .location-action-links {
        margin-top: 16px;

        @include respond-to(mobile) {
            display: flex;
            margin-top: 8px;

            .zupr-tooltip-holder:last-child {
                margin-left: auto;
            }
        }
    }
}

.location-menu {
    border-bottom: 1px solid var(--border);

    @include respond-to(mobile) {
        padding: 0 var(--gutter-size);
        position: sticky;
        top: 60px;
        background: white;
        z-index: 1;
    }

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-right: 32px;
    }
    a {
        color: var(--dark-grey);
        line-height: 24px;
        padding: 16px 0 14px;
        border-bottom: 2px solid transparent;
        display: block;
        margin-bottom: -1px;

        &.active {
            border-color: var(--brand);
        }
    }
}
