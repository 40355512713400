@import '../../shared/settings';

// most of the styling is placed on the component itself in `shared/component/select`
// otherwise we should override all the style with important

.react-select {
    &__input {
        height: auto !important;
    }

    &__option {
        font-weight: normal;

        span {
            &.flag {
                margin-right: 4px;
            }
        }

        &::after {
            display: table;
            clear: both;
            content: ' ';
        }

        &:hover {
            background-color: rgba($lighter, 0.5);
        }

        &:first-child {
            border-top: 0 !important;
            border-radius: var(--input-radius) var(--input-radius) 0 0;
        }

        &:last-child {
            border-radius: 0 0 var(--input-radius) var(--input-radius);
        }
    }

    &__menu {
        overflow: hidden;
    }

    &__menu-list {
        @include styledScrollbar;
    }

    &.before-input {
        width: 190px;

        .react-select__control {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}
