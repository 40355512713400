@import '../../../mixins/respond.scss';

.location-product-item {
    border-radius: 8px;
    border: 1px solid var(--border);

    p {
        font-size: 14px;
        line-height: 20px;
    }

    figure {
        aspect-ratio: 1 / 1;
    }

    .titles {
        h2 {
            font-size: 16px;
            margin: 0;
            line-height: 22px;
            a {
                text-decoration: none;
                color: inherit;
            }
        }
        h3 {
            font-size: 12px;
            font-weight: normal;
            line-height: 19px;
        }
    }

    .price-and-unit {
        .price {
            font-weight: bold;
        }
        .product-location-unit {
            display: block;
            font-size: 12px;
        }
    }

    button {
        margin-top: 16px;
        width: 100%;
    }
}

.location-product-list-item {
    padding: 8px;
    display: flex;
    flex-direction: column;

    figure {
        position: relative;
        height: 174px;

        .badges {
            position: absolute;
            left: 0;
            bottom: 0;
            .badge {
                margin-right: 4px;
            }
        }
    }

    .titles {
        margin: 8px 0;
    }

    .price-and-unit {
        .price-discounted,
        .price-striked {
            margin-left: 0;
            margin-right: 8px;
        }
    }

    a {
        text-decoration: none !important;
        flex-grow: 1;
    }

    button {
        margin-top: 16px;
        width: 100%;
    }
}

.location-product-single-item {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--border);
    display: flex;
    flex-direction: row;

    figure {
        height: 100%;
        max-height: 320px;
        min-width: 50%;
        position: relative;

        .badges {
            position: absolute;
            left: 0;
            bottom: 0;
            .badge {
                margin-right: 4px;
            }
        }
    }

    .price-and-unit {
        text-align: right;
    }

    figure + div {
        margin-left: 16px;
        flex-grow: 1;
    }

    @include respond-to(mobile) {
        flex-direction: column;

        figure + div {
            margin-left: 0;
            margin-top: 16px;
        }

        p {
            display: none;
        }
    }
}
