.location-delivery-service {
    margin: 16px 0 24px;

    &:last-child {
        margin-bottom: 0;
    }

    ul {
        margin: 8px 0;
    }

    .grid-block-detail {
        margin: 8px 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}