@import '../../shared/settings';

.product-variations {
    display: flex;
    line-height: 32px;
    flex-direction: column;

    & + * {
        margin-top: 10px;
    }

    .product-variations-field {
        display: flex;
    }

    label {
        display: block;
        width: 100px;
    }

    .selector-menu {
        width: auto;
    }

    .no-variations {
        display: inline-block;
        padding: 0 10px;
    }

    @include respond-to(mobile) {
        .product-variations-field {
            margin-right: 10px;
            width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }

        label {
            width: calc(100% - 36px);
        }
    }
}