@import '../../shared/settings';

.product-location-stock {
    p {
        margin: 16px 0;
        display: flex;
        align-items: center;

        strong {
            margin-left: 8px;
            font-weight: var(--medium);
        }
    }

    .stock-indicator {
        width: 16px;
        height: 16px;
        margin: 0;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .btn {
        width: 100%;
        margin: 8px 0;
    }

    small {
        font-size: 12px;
        color: var(--dark-grey);
    }

    @include respond-to(mobile) {
        grid-template-columns: 100%;
    }
}
