@import '../../mixins/respond';

.grid-buttons {
    display: flex;
    gap: 8px;
    align-items: right;

    > div {
        display: flex;
        gap: 16px;
    }

    &:only-child {
        margin-top: 0;
    }

    & > .btn:first-child:not(:only-child) {
        margin-right: auto;
    }

    @include respond-to(mobile) {
        flex-direction: column-reverse;
        gap: 16px;

        & > *:only-child {
            margin-left: 0;
        }
    }
}

.grid-block .grid-buttons {
    margin-top: 16px;
    padding: var(--gutter-size);
    margin: var(--gutter-size) var(--negative-gutter-size)
        var(--negative-gutter-size);
}
