@import '../../shared/settings';

.color-filter {
    a {
        display: block;
        margin: 8px 0;
        line-height: 20px;
        color: $dark-grey;
    }

    .color {
        float: left;
        margin-right: 10px;
        border-width: 1px;
        border-style: solid;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        box-sizing: border-box;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .multicolor {
        border: 0;
    }
}
