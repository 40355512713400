@import '../../shared/settings.scss';

.icon {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--dark-grey-rgb), 0.1);

    svg {
        height: auto;
        max-height: 12px;
        width: 12px;
        fill: var(--dark-grey);
    }
}

.icon-small {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    svg {
        width: 8px;
    }
}

.icon-check {
    background-color: var(--danger);

    svg {
        fill: white;
    }

    &.checked {
        background-color: var(--success);
    }

    &.icon-question {
        background-color: var(--warning);
    }
}
