@import '../../shared/settings';

.location-view-card {
    display: grid;
    min-height: 382px;
    grid-template-columns: 1fr 382px;

    .location-info {
        padding: 34px;

        h3 {
            color: $grey;
        }

        svg {
            fill: $grey;
        }
    }
}

.address-and-map-block {

    display: block;

    .google-maps {
        border-radius: 8px;
        height: 20vw;
        margin-bottom: var(--gutter-size);
        max-height: 200px;
    }

    &.wide {

        h4:first-child {
            margin-top: 0;
        }

        @include respond-to-min(1156px) {
            display: grid;
            grid-template-columns: 300px 1fr;
        
            .google-maps {
                order: 2;
                margin-bottom: 0;
                height: unset;
                max-height: unset;
            }
        }
    }

    @include respond-to(mobile) {
        .google-maps {
            height: 50vw;
        }
    }
}

.location-images-grid {
    grid-template-columns: 1fr 1fr 1fr;

    @include respond-to(mobile) {
        display: block;
        background: white;
        padding: 8px;
    }

    .grid-block {
        padding: 0;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 0;
            padding-bottom: calc(100% / (4 / 3));
        }
    }

    button {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        width: 100%;
        padding: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }
}
