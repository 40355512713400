@import '../../shared/settings';

.stock-icon {
    &.available,
    &.deliverable {
        fill: var(--success);
    }

    &.not-available {
        fill: var(--danger);
    }

    &.exact-stock {
        fill: var(--secondary);
    }

    &.pay-and-collect,
    &.reservation,
    &.call-for-stock {
        fill: var(--brand);
    }
}

.stock-indicator {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;

    svg {
        display: block;
        width: 18px;
        height: 18px;
    }

    @include respond-to(mobile) {
        width: 16px;
        height: 16px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .loading {
        margin: 0 8px 0 0;
        width: 8px;
        height: 8px;
    }
}

.cell {
    .stock-indicator + .stock-indicator {
        margin-left: 8px;
    }
}
