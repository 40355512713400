@import '../../shared/settings';

.product-titles {
    display: flex;

    div {
        margin-right: auto;
    }

    h1,
    h2 {
        margin: 0;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 16px;
        font-weight: normal;
    }

    .zupr-tooltip-holder {
        margin-left: 10px;
    }
}

.variations-details {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;

    .pricing {
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .price:not(.price-striked) {
            font-size: 20px;
            font-weight: bold;
        }
    }

    @include respond-to(mobile) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.product-images {
    height: 350px;

    @include respond-to(mobile) {
        min-height: 200px;
        height: calc(100vh - 460px);
        margin-top: var(--gutter-size)
    }
}

.location-product {
    .product-titles {
        margin-bottom: 10px;
    }

    .price-and-unit {
        margin: 16px 0;
        text-align: right;

        .price:not(.price-striked) {
            font-size: 20px;
            font-weight: bold;
        }

        .product-location-unit {
            display: block;
        }
    }
}

.location-product-modal {
    background-color: white;
    padding: 0;

    .product-images {
        height: 400px;
    }
}
